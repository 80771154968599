<template>
  <div class="teacherResultBox">
    <div class="exportBox">
      <table border="1"  id="exportTable">
        <tr>
          <th>序号</th>
          <th v-for="(item,index) in exportColumns" :key="index">
            {{item.name}}
          </th>
        </tr>
        <tr v-for="(item,index) in exportList" :key="index">
          <td>{{index+1}}</td>
          <td v-for="(cc,cidx) in exportColumns" :key="cidx+'colums'">
            {{item[cc.field]}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeacherResult',
  components: {},
  data () {
    return {

    }
  },
  props:['exportList','exportColumns'],
  watch: {

  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style lang="less" type="text/less">
.ant-layout-content{
  height: 100vh!important;
}
.teacherResultBox {
  position: relative;
  top: 0;
  .card-title {
    border-left: 4px solid #1890ff;
    padding-left: 32px;
    color: #000000d9;
    font-size: 16px;
  }
  .searchBox{
    margin-top: 20px;
  }
  .tableBox{
    .ant-pagination-options-size-changer{
      margin-right: 0;
    }
  }
  .primaryColor{
    color: #1890ff;
  }
}
.exportBox{
  display: inline-block;
  height: 50px;
  overflow-y: scroll;
  position: fixed;
  top: 100px;
  z-index: -1;
}
.greenBtn{
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}
.greenBtn:hover,.greenBtn:focus,.greenBtn:active{
  background: #85ce61;
  border-color: #85ce61;
  color: #fff;
}
</style>

